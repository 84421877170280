import React, { useMemo } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
// import PdpNavigation from '../components/slices/PdpNavigation'
// import PdpInfo from '../components/slices/PdpInfo'
// import PdpShipping from '../components/slices/PdpShipping'
import FarmLayout from "../components/FarmLayout"

// Display the title, date, and content of the Post
const PdpFazenda = ({ page }) => {
  // const uid = useMemo(() => (
  //   get(page, '_meta.uid')
  // ), [page])

  // console.log('[PdpFazenda.js] page', page)

  // if(!page) return null;

  return (
    <section>
      <div className="containerGlobal">
        <FarmLayout data={page} isFarmPage={true} />
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allFazendas.edges[0]

  // console.log('doc', doc)

  if (!doc) return null

  const meta = doc.node._meta

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(
    () => get(doc, "node.seo_image.url") || get(doc, "node.main_image.url"),
    [doc]
  )

  return (
    <Layout pathname={props.path} meta={meta} removeImgRule>
      <SEO
        title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      <PdpFazenda page={doc.node} />
    </Layout>
  )
}

export const query = graphql`
  query pdpFazendas($lang: String, $uid: String) {
    prismic {
      allFazendas(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            seo_title
            seo_meta
            seo_image
            body {
              ... on PRISMIC_FazendaBodyText___image {
                type
                label
                primary {
                  image
                  image_aligment
                  text
                }
              }
              ... on PRISMIC_FazendaBodyImages_editorial {
                type
                label
                primary {
                  image_1
                  image_2
                  image_3
                  layout
                }
              }
              ... on PRISMIC_FazendaBodyRelated_products {
                type
                label
                primary {
                  title1
                  products
                }
                fields {
                  related_product {
                    ... on PRISMIC_Product {
                      _meta {
                        uid
                        lang
                        type
                      }
                      family {
                        ... on PRISMIC_Coffee_family {
                          title
                          description
                          illustration_individual
                        }
                      }
                      title
                      sku
                      notes {
                        note
                      }
                      # harvest
                      # current_location
                      # bags_available
                      # harvest_year
                      # min_order
                      # packaging
                      # processing
                      # status
                      # variety
                      # altitude
                      main_image
                      details {
                        title1
                        text
                        show_in_mini_card
                      }
                      fazenda {
                        ... on PRISMIC_Fazenda {
                          title
                          _linkType
                          _meta {
                            uid
                          }
                          country {
                            ... on PRISMIC_Country {
                              title
                              flag
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_FazendaBodyGallery {
                type
                label
                primary {
                  with_slider
                }
                fields {
                  image
                }
              }
            }
            characteristics {
              title1
              text
            }
            country {
              ... on PRISMIC_Country {
                title
                flag
              }
            }
            title
            show_related_products
            main_image
          }
        }
      }
    }
  }
`
